import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex-box van-hairline--bottom"
};
const _hoisted_2 = {
  style: {
    "margin": "var(--van-padding-md) 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_form = _resolveComponent("van-form");
  const _component_countrySelectComp = _resolveComponent("countrySelectComp");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_captchasInfoDIalog = _resolveComponent("captchasInfoDIalog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_form, {
    onSubmit: $options.onSubmit,
    onFailed: $options.onFailed,
    style: {
      "margin": "var(--van-padding-md)"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_field, {
      style: {
        "flex": "0 0 20%"
      },
      readonly: "",
      clickable: "",
      placeholder: "选择国家/地区",
      modelValue: $data.phoneLoginFormData.prefix,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.phoneLoginFormData.prefix = $event),
      onClick: _cache[1] || (_cache[1] = $event => $data.showPrefixPopup = true)
    }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
      modelValue: $data.phoneLoginFormData.phone,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.phoneLoginFormData.phone = $event),
      placeholder: "请输入手机号",
      type: "tel",
      rules: [{
        validator: $options.validatorPhone
      }]
    }, null, 8, ["modelValue", "rules"])]), _createVNode(_component_van_field, {
      modelValue: $data.phoneLoginFormData.code,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.phoneLoginFormData.code = $event),
      placeholder: "请输入验证码",
      clearable: "",
      rules: [{
        required: true,
        message: '请输入验证码'
      }]
    }, {
      button: _withCtx(() => [_createVNode(_component_van_button, {
        type: "primary",
        onClick: $options.onSendCode,
        disabled: $data.countdown > 0
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.countdown > 0 ? `${$data.countdown}秒后重新获取` : "获取验证码"), 1)]),
        _: 1
      }, 8, ["onClick", "disabled"])]),
      _: 1
    }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
      round: "",
      block: "",
      loading: $data.isLoading,
      type: "primary",
      "native-type": "submit"
    }, {
      default: _withCtx(() => [_createTextVNode("登 录")]),
      _: 1
    }, 8, ["loading"]), _withDirectives(_createVNode(_component_van_button, {
      round: "",
      block: "",
      type: "default",
      style: {
        "margin-top": "var(--van-padding-md)"
      },
      "native-type": "button",
      onClick: _cache[4] || (_cache[4] = $event => _ctx.$emit('changeLoginType'))
    }, {
      default: _withCtx(() => [_createTextVNode("账号密码登录")]),
      _: 1
    }, 512), [[_vShow, !$data.isWeChat]]), _withDirectives(_createVNode(_component_van_button, {
      round: "",
      block: "",
      type: "success",
      style: {
        "margin-top": "var(--van-padding-md)"
      },
      "native-type": "button",
      onClick: _cache[5] || (_cache[5] = $event => _ctx.$router.push('/regist'))
    }, {
      default: _withCtx(() => [_createTextVNode("去注册")]),
      _: 1
    }, 512), [[_vShow, !$data.isWeChat]])])]),
    _: 1
  }, 8, ["onSubmit", "onFailed"]), _createVNode(_component_van_popup, {
    show: $data.showPrefixPopup,
    "onUpdate:show": _cache[6] || (_cache[6] = $event => $data.showPrefixPopup = $event),
    position: "right",
    style: {
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_countrySelectComp, {
      onClose: $options.goBack,
      onConfirm: $options.onConfirmPrefix
    }, null, 8, ["onClose", "onConfirm"])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_captchasInfoDIalog, {
    ref: "captchasInfoDIalog",
    onReload: $options.getCaptches,
    onOnConfirm: $options.onConfirm
  }, null, 8, ["onReload", "onOnConfirm"])], 64);
}