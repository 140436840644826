import "core-js/modules/es.array.push.js";
import countrySelectComp from "@/components/countrySelectComp.vue";
import captchasInfoDIalog from "@/components/captchasInfoDIalog.vue";
export default {
  props: ["wechat_oa_token"],
  emits: ["noTeam", "changeLoginType"],
  components: {
    countrySelectComp,
    captchasInfoDIalog
  },
  data() {
    return {
      isWeChat: this.$utils.isWX(),
      // 这里假设$utils已经通过Vue插件机制添加到Vue原型上
      isLoading: false,
      verification_key: "",
      phoneLoginFormData: {
        phone: "",
        code: "",
        prefix: "+86"
      },
      defaultCountrySelectedData: {
        areaName: "中国大陆",
        checkKey: "^(86){0,1}1\\d{10}$",
        code: "86",
        domainAbbreviation: "CN"
      },
      showPrefixPopup: false,
      countdown: 0,
      COUNTDOWN_START: 60
    };
  },
  methods: {
    onConfirm(key) {
      this.verification_key = key;
    },
    validatorPhone(val) {
      let reg = new RegExp(this.defaultCountrySelectedData.checkKey);
      let isValid = reg.test(this.defaultCountrySelectedData.code + "" + val);
      if (isValid) {
        return true;
      } else {
        return "请输入正确的手机号";
      }
    },
    onSendCode() {
      if (!this.phoneLoginFormData.phone) {
        this.$toast({
          type: "fail",
          message: "请输入手机号",
          forbidClick: true
        });
        return false;
      }
      this.$refs.captchasInfoDIalog.showDialog(this.phoneLoginFormData.phone, true);
      this.getCaptches();
    },
    getCaptches() {
      // 开始倒计时
      this.countdown = this.COUNTDOWN_START;
      const intervalId = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(intervalId); // 倒计时结束，清除定时器
        }
      }, 1000); // 每秒更新一次倒计时
    },
    onConfirmPrefix(value) {
      this.phoneLoginFormData.prefix = `+${value.code}`;
      this.showPrefixPopup = false;
      this.defaultCountrySelectedData = value;
    },
    goBack() {
      this.showPrefixPopup = false;
    },
    onSubmit() {
      this.isLoading = true;
      // console.log("提交的数据:", this.phoneLoginFormData);
      this.$axios.phoneLogin({
        verification_key: this.verification_key,
        verification_code: this.phoneLoginFormData.code,
        invite_id: "",
        wechat_oa_token: this.wechat_oa_token || null
      }, true).then(res => {
        if (res.code === 1) {
          this.isLoading = false;
          this.$store.commit("SET_INVITE_ID", "");
          this.$store.commit("SET_USER_INFO", res.data);
          this.$store.commit("SET_GLOBAL_MODEL_ID", res.data.user_info.gpt_model_id);
          this.$store.dispatch("getAllGptModels");
          this.$store.dispatch("getBillingRules");
          this.$store.dispatch("getUserTeamList");
          if (res.data.user_info.curr_team_id) {
            this.$store.dispatch("getGptConfigDatas");
            this.$router.push("/");
          } else {
            // 创建团队的流程
            this.$notify({
              message: "当前登录账号下无团队，请先创建团队",
              type: "danger",
              duration: 1000,
              onClose: () => {
                this.isLoading = false;
                this.$emit("noTeam");
              }
            });
          }
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    }
  }
};