import "core-js/modules/es.array.push.js";
import CustomActionSheet from "@/components/CustomActionSheet.vue";
import phoneComp from "./comp/phone.vue";
import accountComp from "./comp/account.vue";
import { createTeamPrompt } from "@/util/createTeamPrompt";
import { mapState } from "vuex";
export default {
  components: {
    CustomActionSheet,
    phoneComp,
    accountComp
  },
  data() {
    return {
      isWeChat: this.$utils.isWX(),
      // 这里假设$utils已经通过Vue插件机制添加到Vue原型上
      isPhoneLogin: !this.$utils.isWX(),
      actionSheetRef: null,
      actionArray: [],
      wechat_oa_token: ""
    };
  },
  created() {
    this.isPhoneLogin = !this.isWeChat;
    if (this.isWeChat) {
      this.$loadingToast({
        message: '登录中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      });
      if (this.$utils.getQueryString("state") && this.$utils.getQueryString("code")) {
        // 走php接口
        this.wxLoginCallback();
      } else {
        this.wxLogin();
      }
    }
  },
  methods: {
    wxLoginCallback() {
      this.$axios.wechatCallback({
        code: this.$utils.getQueryString("code")
      }).then(res => {
        if (res.code === 1) {
          this.$closeToast();
          this.$store.commit("SET_USER_INFO", res.data);
          this.$store.commit("SET_GLOBAL_MODEL_ID", res.data.user_info.gpt_model_id);
          this.$store.dispatch("getAllGptModels");
          this.$store.dispatch("getBillingRules");
          this.$store.dispatch("getUserTeamList");
          if (res.data.user_info.curr_team_id) {
            this.$store.dispatch("getGptConfigDatas");
            this.$router.push("/");
          } else {
            // 创建团队的流程
            this.$notify({
              message: "当前登录账号下无团队，请先创建团队",
              type: "danger",
              duration: 1000,
              onClose: () => {
                this.noTeamHandler();
              }
            });
          }
        } else {
          this.$closeToast();
          this.isWeChat = false;
          this.isPhoneLogin = true;
          this.wechat_oa_token = res.data.wechat_oa_token;
        }
      }).catch(() => {});
    },
    wxLogin() {
      var fromurl = location.href;
      var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6f3ec8303a8772f9&redirect_uri=" + encodeURIComponent(fromurl) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
      window.location.href = url;
    },
    openActionSheet(type) {
      if (type === 1 || type === 2) {
        this.actionArray = [{
          name: "账号密码登录"
        }];
      } else if (type === 3) {
        this.actionArray = [{
          name: "手机号登录"
        }];
      }
      this.$refs.actionSheetRef.show();
    },
    handleActionSelect(action) {
      if (action.name === "微信登录" && this.$utils.isWX()) {
        console.log("执行微信登录");
      } else if (action.name === "账号密码登录") {
        this.isPhoneLogin = false;
      } else {
        this.isPhoneLogin = true;
      }
    },
    noTeamHandler() {
      createTeamPrompt({
        models: this.gpt_models,
        onConfirm: data => {
          console.log("Form submitted with:", data);
          this.$axios.createTeamHandle(data).then(res => {
            if (res.code === 1) {
              this.$store.commit("CHANGE_TEAM_PARAM", {
                curr_team_id: res.data.team_id,
                curr_team_name: data.team_name,
                gpt_model_id: data.gpt_model_id,
                team_storage_id: res.data.team_storage_id
              });
              this.$store.commit("SET_GLOBAL_MODEL_ID", data.gpt_model_id);
              this.$store.dispatch("getUserTeamList");
              this.$store.dispatch("getGptConfigDatas");
            }
          }).catch(() => {});
        },
        onCancel: () => {
          console.log("Dialog canceled");
        }
      });
    }
  },
  computed: {
    ...mapState({
      gpt_models: state => state.gpt_models
    })
  }
};