import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      isLoading: false,
      accountLoginFormData: {
        number: "",
        password: ""
      }
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.$axios.numberLogin({
        name: this.accountLoginFormData.number,
        pass: this.accountLoginFormData.password,
        invite_id: this.$store.state.invite_id
      }).then(res => {
        if (res.code === 1) {
          this.$store.commit("SET_INVITE_ID", "");
          this.$store.commit("SET_USER_INFO", res.data);
          this.$store.commit("SET_GLOBAL_MODEL_ID", res.data.user_info.gpt_model_id);
          this.$store.dispatch("getAllGptModels");
          this.$store.dispatch("getBillingRules");
          this.$store.dispatch("getUserTeamList");
          if (res.data.user_info.curr_team_id) {
            this.$store.dispatch("getGptConfigDatas");
            this.isLoading = false;
            this.$router.push("/");
          } else {
            // 创建团队的流程
            this.$notify({
              message: "当前登录账号下无团队，请先创建团队",
              type: "danger",
              duration: 1000,
              onClose: () => {
                this.isLoading = false;
                this.$emit("noTeam");
              }
            });
          }
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    }
  }
};